body {
  background-color: rgba(26, 108, 225, 0.03); }

.navbar-header {
  border-bottom-color: #e5eefc;
  box-shadow: 0 0 25px rgba(26, 108, 225, 0.1); }
  .navbar-header .navbar-right .dropdown-menu {
    border-color: #e5eefc;
    border-top-color: transparent;
    box-shadow: 0 15px 15px rgba(26, 108, 225, 0.1); }
  .navbar-header .navbar-right .dropdown-notification .dropdown-menu,
  .navbar-header .navbar-right .dropdown-message .dropdown-menu {
    margin-top: 18.5px; }
    .navbar-header .navbar-right .dropdown-notification .dropdown-menu::before,
    .navbar-header .navbar-right .dropdown-message .dropdown-menu::before {
      opacity: 0; }

.dropdown-profile .dropdown-menu {
  margin-top: 13.5px; }
  .dropdown-profile .dropdown-menu::before {
    opacity: 0; }

@media (min-width: 992px) {
  .navbar-menu-sub {
    padding-top: 10px;
    border-color: #e5eefc;
    border-top-color: transparent;
    box-shadow: 0 15px 15px rgba(26, 108, 225, 0.1); } }

.navbar-menu-sub::before {
  opacity: 0; }

@media (min-width: 992px) {
  .navbar-menu-sub {
    margin-top: 0; } }

.card {
  border-color: transparent;
  box-shadow: 0 0 25px rgba(26, 108, 225, 0.1); }

.card-header,
.card-footer,
.list-group-item,
.table tr th,
.table tr td,
.bd {
  border-color: #e9f1fc; }

.footer {
  border-top-color: transparent;
  box-shadow: 0 0 25px rgba(26, 108, 225, 0.1); }
