$iCede-primary: #22559c;
$iCede-info: #22559c;
$iCede-warning: #fb6340;
$iCede-danger: #d22828;
$iCede-success: #2dce50;
$iCede-black: #0a0a0a;

.badge-primary {
  background-color: $iCede-primary;
}
.badge-info {
  background-color: $iCede-info;
}
.badge-success {
  background-color: $iCede-success;
  color: lighten($iCede-success, 45%);
}
.badge-warning {
  background-color: $iCede-warning;
  color: lighten($iCede-warning, 30%);
}
.badge-danger {
  background-color: $iCede-danger;
  color: lighten($iCede-danger, 45%);
}

.badge-dot {
  &.badge-primary {
    background-color: transparent;
    color: $iCede-primary;
  }
  &.badge-success {
    background-color: transparent;
    color: $iCede-success;
  }
  &.badge-warning {
    background-color: transparent;
    color: $iCede-warning;
  }
  &.badge-danger {
    background-color: transparent;
    color: $iCede-danger;
  }
}

.bg-primary {
  background-color: $iCede-primary;
}
.bg-success {
  background-color: $iCede-success;
}
.bg-warning {
  background-color: $iCede-warning;
}
.bg-danger {
  background-color: $iCede-danger;
}

input[type="text"].clean-input {
  font-size: 0.9em;
  color: $iCede-primary;
  border-color: #f1f1f1;
}
input[type="text"].clean-input:hover {
  border: 1px solid #cad1d7;
}
input[type="text"].clean-input:focus {
  border: 1px solid #787a80;
}
$changeBorder: #ffd4b3;
input[type="text"].clean-input.changes {
  color: $iCede-primary;
  border-color: $changeBorder;
}
input[type="text"].clean-input.changes:hover {
  border-color: $changeBorder;
}
input[type="text"].clean-input.changes:focus {
  border-color: $changeBorder;
}

.text-icede-info {
  color: $iCede-primary;
}
.bg-gradient-info {

}
.bg-gradient-danger {
  background: rgb(143,9,9);
  background: linear-gradient(90deg, rgba(143,9,9,0.1) 0%, rgba(121,9,9,0.10407913165266103) 35%, rgba(255,0,0,0.2) 100%);
}
.bg-gradient-warning {
  background: rgb(143,114,9);
  background: linear-gradient(90deg, rgba(143,114,9,0.1) 0%, rgba(121,90,9,0.10407913165266103) 35%, rgba(255,184,0,0.2) 100%);
}
.bg-gradient-success {
  background: rgb(42,143,9);
  background: linear-gradient(90deg, rgba(42,143,9,0.1) 0%, rgba(45,121,9,0.10407913165266103) 35%, rgba(0,255,46,0.2) 100%);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.card.hoverable {
  border: 1px solid transparent;
}
.card.hoverable:hover {
  background-color: #fafafa;
  border: 1px solid #dde;
}

.text-primary-white-on-hover {
  color: $iCede-primary;
}
.text-primary-white-on-hover:hover {
  color:white !important;
}
.hide {
  display:  none;
}